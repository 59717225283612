.App {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* unvisited link */
a:link {
  color: grey !important;
}

/* visited link */
a:visited {
  color: grey !important;
}

/* mouse over link */
a:hover {
  color: darkgrey !important;
}

/* selected link */
a:active {
  color: darkgrey !important;
}
.navbar {
  background-color: #000000!important;
}
.navbar-toggler-icon {
  background-image: url("images/icons8-menu-24.png") !important;
}

.heroBar{
  padding-top: 50px;
}


@media(max-width:1200px){
  .heroBar{
    padding-top: 10px;
  }

  #logo{
    padding-bottom:10px;
  }

  .card{
    margin-bottom:10px;
  }

  .footer-col{
    padding-left: 0px;
  }

  .cardImage{
    margin:0px !important;
  }

  .col-md-8{
    padding:10px !important;
  }

}